import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.css'; // Ensure to create this file for custom styling
import icon1 from '../../Assets/images/icon1.svg';
import icon2 from '../../Assets/images/icon2.svg';
import icon3 from '../../Assets/images/icon3.svg';

gsap.registerPlugin(ScrollTrigger);

const ServicesSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const serviceBoxes = sectionRef.current.querySelectorAll('.service-box');

    serviceBoxes.forEach((box, index) => {
      const directionX = index === 0 ? -200 : index === 2 ? 200 : 0; // Left for first, Right for third, 0 for middle
      const directionY = index === 1 ? 200 : 0; // Down for middle box

      gsap.from(box, {
        x: directionX,
        y: directionY,
        opacity: 0,
        duration: 0.2, // Reduced animation duration
        ease: 'power2.out',
        scrollTrigger: {
          trigger: box,
          start: 'top 85%', // Trigger when the top of the box hits 75% of the viewport
          end: 'bottom center', // End when the bottom of the box hits 25% of the viewport
          scrub: 1,
          // markers: true, // Uncomment for debugging
        },
      });
    });
  }, []);

  return (
    <section className="services-section py-5" ref={sectionRef} id="methodology">
      <div className="container text-center">
        <h2 className="mb-4">OUR SERVICES</h2>
        <p className="mb-5">
          Benefit from our dedicated sales manager leading the entire process, providing regular performance reports to keep you informed.
        </p>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="service-box p-4">
              <img src={icon1} alt="Lead Generation" className="mb-3" />
              <span className="line"></span>
              <h5>Lead Generation</h5>
              <p>
                Work with our team to create tailored lead lists and messaging, targeting your ICP to convert them into qualified leads through strategic cold calling.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="service-box p-4">
              <img src={icon2} alt="Closing Deals" className="mb-3" />
              <span className="line"></span>
              <h5>Closing Deals</h5>
              <p>
                Get help from our experts who will handle your entire sales process, from discovery calls to contract signing, making it all easy for you.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="service-box p-4">
              <img src={icon3} alt="Sales Management" className="mb-3" />
              <span className="line"></span>
              <h5>Sales Management</h5>
              <p>
                Benefit from our dedicated sales manager leading the entire process, providing regular performance reports to keep you informed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
