import React from 'react'
import beforeImg from '../../Assets/images/Before-mobile.svg'
import './style.css'
function beforeMob
() {
  return (
    <div>
<div className="before-img">
<h3>CASE STUDY: PAGINATION</h3>
        <p>A pagination is a Cloud-based software that automates the creation of catalogues and price lists for enterprise manufacturers in the EMEA and US markets. They were struggling for some reason.
</p>
        <h3>Before</h3>
<img src={beforeImg} alt="" />
</div>
    </div>
  )
}

export default beforeMob
