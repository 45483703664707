import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import video1 from '../../Assets/video/master.mp4';
import video2 from '../../Assets/video/master1.mp4';
import video3 from '../../Assets/video/master2.mp4';

gsap.registerPlugin(ScrollTrigger);

const VideoSection = () => {
  const videoSectionRef = useRef(null); // Create a reference for the video section

  useEffect(() => {
    const videoCards = videoSectionRef.current.querySelectorAll('.card'); // Select all video cards

    videoCards.forEach((card, index) => {
      const directionX = index === 0 ? -200 : index === 2 ? 200 : 0; // Left for first, Right for third, 0 for middle

      gsap.fromTo(card,
        {
          x: directionX,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: card,
            start: 'top 90%', // Trigger when the top of the card hits 75% of the viewport
            end: 'bottom 25%', // End when the bottom of the card hits 25% of the viewport
            scrub: 1,
            onLeave: () => {
              gsap.to(card, {
                opacity: 0,
                duration: 0.5,
                ease: 'power2.in',
              }); // Animate out on leave
            },
            onEnterBack: () => {
              gsap.to(card, {
                opacity: 1,
                duration: 0.5,
                ease: 'power2.out',
              }); // Animate in on entering back
            },
    
          },
        }
      );
    });
  }, []);

  return (
    <div className="container my-5 video-section" ref={videoSectionRef}>
      <h2 className='text-center heading'>WHAT OUR CLIENTS SAY</h2>
      <div className="row">
        {/* First Video Card */}
        <div className="col-md-12 col-lg-4">
          <div className="card mb-4 ">
            <div className="video-wrapper embed-responsive embed-responsive-16by9">
              <video
                className="embed-responsive-item"
                autoPlay
                loop
                muted
                playsInline
                controls
                src={video1}
                title="Joseph"
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="card-body text-center">
              <p className="card-text">Joseph, from One Dial Solutions in the Philippines, faced challenges in getting new clients for his startup. We worked together to create a strong plan to attract customers. First, we defined his ideal customer and made a clear message about what his company offers. Then, we tested different ways to reach out to potential clients.
                <br/>
In just two weeks, Joseph saw great results. He received more responses from prospects and successfully booked appointments and demo sessions. This helped One Dial Solutions grow and connect with new clients. We're proud to have helped Joseph turn his challenges into success!</p>
              <h5 className="card-title">Joseph</h5>
              <p className="card-subtitle mb-2">Head Of Business Development</p>
            </div>
          </div>
        </div>

        {/* Second Video Card */}
        <div className="col-md-12 col-lg-4">
          <div className="card mb-4 video-card-2">
            <div className="video-wrapper embed-responsive embed-responsive-16by9">
              <video
                className="embed-responsive-item"
                autoPlay
                loop
                muted
                playsInline
                controls
                src={video2}
                title="Michele Vardi"
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="card-body text-center">
              <p className="card-text">Michele, the owner of a marketing agency, needed assistance in bringing more structure to his business and improving his team's performance. We collaborated to develop a clear outreach strategy and provided him with tailored scripts to guide his team.
              <br/>
Within a week and a half, Michele saw positive changes. He felt more confident in how to implement the new processes, and his team was better equipped to handle outreach. As a result, his agency is now more organized, and he’s seeing better engagement with potential clients. We’re proud to have supported Michele in improving his agency’s performance and success!</p>
              <h5 className="card-title">Michele Vardi</h5>
              <p className="card-subtitle mb-2">CEO & Founder</p>
            </div>
          </div>
        </div>

        {/* Third Video Card */}
        <div className="col-md-12 col-lg-4 ">
          <div className="card mb-4 video-card-3">
            <div className="video-wrapper embed-responsive embed-responsive-16by9">
              <video
                className="embed-responsive-item"
                autoPlay
                loop
                muted
                controls
                playsInline
                src={video3}
                title="Massimiliano Bohm"
              >
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="card-body text-center">
              <p className="card-text">We worked with Max and his team to help build a strong pipeline for their new products. They needed to manage a large number of potential clients and quickly qualify or disqualify leads. We provided professional outbound support, and within a short time, we were booking meetings and driving results. Max appreciated our ability to quickly learn their product, adapt our pitch, and bring effective results from the start. He found our service reliable, fast, and highly effective, and recommends us to anyone looking to grow their pipeline.</p>
              <h5 className="card-title">Massimiliano Bohm</h5>
              <p className="card-subtitle mb-2">CEO & Founder</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
