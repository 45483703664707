// Import React
import React, { useEffect, useRef } from 'react';

// Import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import your custom CSS for the slider
import './style.css';

// Import Bootstrap for responsiveness
import 'bootstrap/dist/css/bootstrap.min.css';

// Import GSAP for animations
import { gsap } from 'gsap';

// Images
import josep from '../../Assets/images/josep.svg';
import luca from '../../Assets/images/Luca.svg';
import velerio from '../../Assets/images/valerio.svg';
import luke from '../../Assets/images/luke.svg';
import Michele from '../../Assets/images/michele.svg';
import Frank from '../../Assets/images/frankw.svg';
import Deviana from '../../Assets/images/davinia.svg';

// Testimonial Slider Component
const TestimonialSlider = () => {
    const testimonials = [
       
        {
            name: "Joseph",
            title: "Head of Business Development",
            rating: 5,
            text: "Hi there, my name is Joseph. I'm from one dial solutions BPO in contact center. We are a startup company based here in the Philippines and we specialize in customer service, sales and virtual assistants. As a startup company, we have encountered a lot of challenges in terms of lead generation and attracting possible clients to partner with us. Paul has helped us develop an entire go to market strategy, defining ideal customer profile, volume proposition and messaging fully ICPV based and that booking quality appointments, front post discovery and demo sessions, as well as a b testing, messaging plus sales process optimization. After a two week session with fall, we have tremendously increased our responses from our prospects. As a matter of fact, we're very happy because we've already started to book appointments and demo sessions with them.",
            time: "1 Week Ago",
            img: josep 
        },
        {
            name: "Valerio Morossi",
            title: "Head of Sales",
            rating: 5,
            text: "Paolo did an excellent job in contacting potential new clients. His communication was clear and consistent, demonstrating professionalism and proactivity. Thanks to him, we saw a significant increase in appointments and interest. We highly recommend Paolo and the OutboundHub team for sales development projects.",
            time: "1 Week Ago",
            img: velerio
        },
        {
            name: "Luca Vocella",
            title: "CEO & Founder",
            rating: 5,
            text: "''It's rare to come across someone as motivated and enthusiastic as Paul and his team. They consistently went above and beyond to ensure that company expectations are met. Their positive attitude and strong work ethic is contagious, they have plenty of ideas, energy and empathy''.",
            time: "1 Week Ago",
            img: luca  
        }
        ,
        {
            name: "Luke Shalom",
            title: "CEO & Founder",
            rating: 5,
            text: "''Paolo is an incredible sales person. He advised me on my sales process when I first started to build my agency. His process to discovery really helped me to uncover deeper into buyer needs which was critical in the initial days of getting my first clients. Thankyou !''",
            time: "4 Months Ago",
            img: luke  
        }
        ,
        {
            name: "Michele Vardi",
            title: "CEO & Founder",
            rating: 5,
            text: "Hi, I'm Michele. I have a marketing agency, and a couple weeks ago, I decided to contact Paul because I needed more structure. My team wasn't performing as I wanted. I am now implementing way more outreach into my agency, and I needed some help. And what Paul did, he immediately understood all my problems and what I needed. And in really, a week, a week and a half, he really helped me. He gave me all the scripts. He absolutely gave me more structure now, and I feel way better now that I know how to implement it in my agency.",
            time: "5 Months Ago",
            img: Michele  
        }
        ,
        {
            name: "Davinia Velasco",
            title: "CEO & Founder",
            rating: 5,
            text: "''Paolo is a great professional who has helped us greatly in growing our business. Thanks to his strategic vision and all his advice, we have achieved excellent results and obtained high-quality leads, which have ultimately turned into clients. Paolo and his team's commitment to our project is worth highlighting.''",
            time:"3 months ago",
            img: Deviana 
        },
        {
            name: "Frank W. Buonanotte",
            title: "Vice President",
            rating: 5,
            text: "'Paolo is not only a dynamic entrepreneur and skilled salesman but also possesses what I consider to be exemplary character. His business acumen and sales expertise, combined with a strong ethical foundation, contribute to his potential and success. Highly recommended for those seeking a results-driven professional with integrity.''",
            time: "6 months ago",
            img: Frank  
        }
    ];

  const swiperRef = useRef(null);

  // GSAP animation for testimonials
  useEffect(() => {
    const slides = document.querySelectorAll('.testimonial-wrapper');
    
    gsap.fromTo(
      slides,
      { opacity: 0, x: '-100%', y: 500 }, // Start state
      {
        opacity: 1,
        x: '0%',
        y: 0,
        scrollTrigger: {
          trigger: '.testimonial-wrapper',
          start: 'top 90%',
          end: 'bottom 25%',
        },
      }
    );
  }, []);
  

  return (
    <div className="testimonial-wrapper">
      <div className="container">
        <h2 className="text-center mt-0 pt-5 heading">WHAT OUR CLIENTS SAY</h2>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={40}
          breakpoints={{
            320: { slidesPerView: 1 }, // Mobile: 1 slide at a time
            768: { slidesPerView: 2 }, // Tablet: 2 slides at a time
            1200: { slidesPerView: 2 }, // Desktop: 3 slides at a time
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={() => {
            const activeSlides = swiperRef.current.slides;
            gsap.fromTo(
              activeSlides,
              { opacity: 0, y: 50 },
              { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
            );
          }}
          className="testimonial-swiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="testimonial-card">
                <div className="testimonial-header">
                  <img
                    src={testimonial.img}
                    alt={`${testimonial.name}`}
                    className="profile-img"
                  />
                  <div className="header-text">
                    <h3>{testimonial.name}</h3>
                    <p>{testimonial.title}</p>
                    <div className="rating">
                      {'★'.repeat(testimonial.rating)}
                    </div>
                  </div>
                </div>
                <div className="testimonial-body">
                  <p>{testimonial.text}</p>
                </div>
                <div className="testimonial-footer">
                  <p>{testimonial.time}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

// Export the component
export default TestimonialSlider;
