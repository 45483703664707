import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './style.css';
import earth from '../../Assets/images/earth.png';
import satelite from '../../Assets/images/satelite.svg';
import asteroid from '../../Assets/images/asteroid2.svg';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PartnerSection from '../Partner-section';

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const earthRef = useRef(null);
  const satelliteRef = useRef(null);
  const asteroidRef = useRef(null);
  const sectionRef = useRef(null);


  useEffect(() => {
    const screenWidth = window.innerWidth;

    // Values for desktop
    let earthX = 350;
    let earthY = 983;
    let satelliteX = -544;
    let satelliteY = 1365;
    let asteroidX = 55;
    let asteroidY = 421;

    // Adjust values for mobile (screen width less than 768px)
    if (screenWidth < 769) {
      earthX = 160;
      earthY = 669;
      satelliteX = -190;
      satelliteY = 1445;
      asteroidX = 20;
      asteroidY = 200;
    }

    // Adjust values for tablet devices (between 768px and 1024px)
    if (screenWidth >= 768 && screenWidth <= 1024) {
      earthX = 160;  // Adjust as per tablet layout
      earthY = 800;  // Adjust as per tablet layout
      satelliteX = -300;  // Adjust as per tablet layout
      satelliteY = 830;  // Adjust as per tablet layout
      asteroidX = 40;  // Adjust as per tablet layout
      asteroidY = 350;  // Adjust as per tablet layout
    }

    if (screenWidth < 425) {
      earthX = 142;
      earthY = 576;
      satelliteX = -177;
      satelliteY = 895;
      asteroidX = 20;
      asteroidY = 200;
    }

    // Earth animation
    gsap.to(earthRef.current, {
      x: earthX,
      y: earthY,
      rotate: 360,
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top bottom',
        end: 'top top',
        scrub: 2,
      },
    });

    // Satellite animation
    gsap.to(satelliteRef.current, {
      x: satelliteX,
      y: satelliteY,
      rotate: 152,
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top bottom',
        end: 'top top',
        scrub: 2,
      },
    });

    // Asteroid animation
    gsap.to(asteroidRef.current, {
      x: asteroidX,
      y: asteroidY,
      rotate: 206,
      scrollTrigger: {
        trigger: sectionRef.current,
        start: 'top bottom',
        end: 'top top',
        scrub: 2.5,
      },
    });

  }, []);

  return (
    <>
      <div className="hero-section">
        <Container className="hero-container">
          <Row className="align-items-center">
            <Col md={6} sm={6} xs={12} className="hero-content">
              <h1>Outbound sales agency for high-impact revenue growth</h1>
              <p>Maximise your revenue without adding headcount.</p>
              <p>Bringing revenue is your number one priority, but you don't know where to start. We fix that.</p>
              <p className='hero-para'>OutboundHub's mission is to become the go-to partner for SaaS companies seeking to increase revenue and growth through an outsourced outbound process built on your unique ICP and market dynamics.</p>
              <Button variant="primary" href='https://calendly.com/paolo-upwork2000/30min' className="get-quote-btn">BOOK A MEETING</Button>
          
            </Col>

            <Col md={6} sm={6} xs={6} className="hero-image text-center">
              <img ref={satelliteRef} src={satelite} alt="Satellite" className="satellite" />
              <img ref={earthRef} src={earth} alt="Earth" className="earth" />
              <img ref={asteroidRef} src={asteroid} alt="Asteroid" className="asteroid" />
            </Col>
          </Row>
        </Container>
      </div>

      <div ref={sectionRef} className='partner'>
        <PartnerSection />
      </div>
    </>
  );
};

export default Hero;
