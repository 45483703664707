import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './style.css'; 
import linkedin from '../../Assets/images/linkedin.svg'

gsap.registerPlugin(ScrollTrigger);

const Commitment = () => {
  useEffect(() => {
    const zoomIn = gsap.fromTo(
      ".zoom-element",
      { scale: 0.8, opacity: 0.8 }, // Start state
      {
        scale: 1,
        opacity: 1,
        scrollTrigger: {
          trigger: ".zoom-element",
          start: "top 80%", // Start when the top of the element hits 80% of the viewport height
          end: "bottom 20%", // End when the bottom of the element hits 20% of the viewport height
          scrub: true, // Smooth animation
        },
      }
    );

    return () => {
      zoomIn.kill(); // Clean up the animation on component unmount
    };
  }, []);

  return (
    <section className="commitment-section">
      <div className="container">
        <div className="container text-center zoom-element">
          <div className="card  p-5 mx-auto text-white">
            <h2>Your Business - Our Commitment!</h2>
            <p className="mb-4">We're more than just a sales partner, we're committed to your business's success.</p>
            <button className="btn btn-primary"    
                  onClick={() => window.open("https://calendly.com/paolo-upwork2000/30min", "_blank")}>BOOK A CALL</button>
          </div>
        </div>
        <hr className="mt-5 bottom-hr" />

        <div className="bottom-footer">
          <div className="social-links">
            <a href="https://www.linkedin.com/in/paololipari2000/"><img src={linkedin} alt="LinkedIn" /></a>
          </div>
          <div className="development-company">
            Copyright 2024 <a href="#"> Outbound Hub </a> All Rights Reserved.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Commitment;
