import React, { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import ScrollTrigger
import './style.css'; // Custom CSS file for positioning
import earthGlobe from "../../Assets/images/earth.png";
import stars from "../../Assets/images/globe-star.svg";
import shine from '../../Assets/images/center-eclip.svg';
import mobwsa from "../../Assets/images/wsa-mob.svg";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const WhatSetsUsApart = () => {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.globe-wsa', // The section that triggers the animation
        start: 'top 80%', // Start when the top of the section hits 80% of the viewport

        once: true, // Animation will only occur once
      },
    });

    // Left-side features animation
    const leftFeatures = gsap.utils.toArray('.feature-left');
    leftFeatures.forEach((feature, index) => {
      tl.fromTo(feature, 
        {
          opacity: 0,
          x: -50, // Start from left
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          stagger: 0.5, // Stagger for left features
          onComplete: () => {
            // Blink animation for each left feature after appearing
            gsap.to(feature, {
              opacity: 0, // Fade out
              duration: 0.2,
              yoyo: true, // Return to original opacity
              repeat: 1, // Repeat once for blink
              delay: 0.2 * index, // Stagger blink effect
            });
          }
        }
      );
    });

    // Right-side features animation
    const rightFeatures = gsap.utils.toArray('.feature-right');
    rightFeatures.forEach((feature, index) => {
      tl.fromTo(feature, 
        {
          opacity: 0,
          x: 50, // Start from right
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          stagger: 0.5, // Stagger for right features
          onComplete: () => {
            // Blink animation for each right feature after appearing
            gsap.to(feature, {
              opacity: 0, // Fade out
              duration: 0.2,
              yoyo: true, // Return to original opacity
              repeat: 1, // Repeat once for blink
              delay: 0.2 * index, // Stagger blink effect
            });
          }
        },
        "<+=0.5" // Start right features after a slight delay after left features
      );
    });

  }, []);

  return (
    <Container fluid className="text-center py-5 globe-wsa" style={{ backgroundColor: '#000', color: '#fff', minHeight: 'auto' }}>
      <h2>WHAT SETS US APART?</h2>
      <p>Your sales partner who wants to see you grow</p>

      <div className="position-relative globe-container desktop-wsa">
        {/* Globe Image */}
        <img
          src={earthGlobe}
          alt="Globe"
          className="img-fluid globe-img"
        />
        <img className='stars' src={stars} alt="Stars" />
        <img className='shine' src={shine} alt="Shine" />

        {/* Left side text */}
        <div className="feature feature-left top-left">
          <p>Deep market knowledge</p>
        </div>
        <div className="feature feature-left mid-left">
          <p>A passion for sales excellence</p>
        </div>
        <div className="feature feature-left bottom-left">
          <p>Complete end-to-end sales guidance</p>
        </div>

        {/* Right side text */}
        <div className="feature feature-right top-right">
          <p>Multilingual approach (English, Dutch, Italian, Spanish, and German)</p>
        </div>
        <div className="feature feature-right mid-right">
          <p>Boost revenue without breaking the bank</p>
        </div>
        <div className="feature feature-right bottom-right">
          <p>Proven strategies that help your business grow</p>
        </div>
      </div>
      <div className="mob-wsa">
        <img src={mobwsa} alt="" />
      </div>
      {/* Call Now Button */}
      <div className="mt-4 pt-4 ">
        <h4 className='pb-4'>Contact us today to discuss your project!</h4>
        <Button variant="primary" className="call-now-btn"
            onClick={() => window.open("https://calendly.com/paolo-upwork2000/30min", "_blank")}
        >BOOK A CALL</Button>
      </div>
    </Container>
  );
};

export default WhatSetsUsApart;
