import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.css'; // Custom styles

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const WhyWorkWithUs = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // Select all card elements
    const cards = sectionRef.current.querySelectorAll('.card');

    cards.forEach((card, index) => {
      // Determine animation direction based on index (even left, odd right)
      const xMovement = index % 2 === 0 ? -300 : 300; // Left for even, right for odd

      gsap.from(card, {
        x: xMovement, // Horizontal movement direction
        opacity: 0,
        duration: 1, // Reduced animation duration
        ease: 'power2.out',
        scrollTrigger: {
          trigger: card, // Trigger animation on each card
          start: 'top 80%', // Animation starts when card is 80% into the viewport
          end: 'top 10%', // Ends when card reaches 50% of viewport
          scrub: 2, // Smooth scroll-based animation
  
        },
      });
    });
  }, []);

  return (
    <section className="why-work-with-us text-white py-5" ref={sectionRef}>
      <div className="container">
        <h2 className="text-center mb-4">Why Work With Us?</h2>
        <div className="row">
          {/* First Card */}
          <div className="col-md-12 mb-4 col-lg-6">
            <div className="card text-white h-100 p-3 card-work-1">
              <div className="card-body">
                <h5 className="card-title">
                Over-reliance on inbound leads and personal networks has dried up your sales pipeline, resulting in inconsistent income and missed targets.

                </h5>
                <p className="card-text">
                We build your entire sales process from scratch so that you can start closing more deals faster. Save months figuring out your sales strategy.
                </p>
              </div>
            </div>
          </div>

          {/* Second Card */}
          <div className="col-md-12 mb-4 col-lg-6">
            <div className="card text-white h-100 p-3">
              <div className="card-body">
                <h5 className="card-title">
                Forget high hiring costs and complex bureacracy

                </h5>
                <p className="card-text">
                 Get a dedicated sales team working for you. You'll spend a fraction of the cost of a full-time hire, with no management headaches. Save thousands of dollars on recruitment and employee overhead.


                </p>
              </div>
            </div>
          </div>

          {/* Third Card */}
          <div className="col-md-12 mb-4 col-lg-6">
            <div className="card text-white h-100 p-3 card-work-3">
              <div className="card-body">
                <h5 className="card-title">
                Your outbound efforts didn't result in what was expected. Poor data, lack of knowledge, and increasing challenges have kept you from meeting your sales targets.

                </h5>
                <p className="card-text">
                We're SaaS sales experts with 15 years of experience, ready to deliver results within just weeks of starting the project.
                </p>
              </div>
            </div>
          </div>

          {/* Fourth Card */}
          <div className="col-md-12 mb-4 col-lg-6">
            <div className="card text-white h-100 p-3">
              <div className="card-body">
                <h5 className="card-title">
                 Hiring a new salesperson takes 8 weeks and it takes another 6-8 months to train them to be fully productive.
                </h5>
                <p className="card-text">
                We rely on experienced sales professionals, not automation, to break through the toughest sales barriers.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="text-center mt-5 why-disc">
          <p className="lead lead-heading">Effortlessly fill your pipeline and close deals faster.</p>
          <p className="lead">Drive revenue growth with a dedicated sales team.</p>
          <p className="lead">
            We aim to boost your sales by improving lead quality and streamlining your sales process.
          </p>
          <h3>You can’t do everything on your own.<br /> You need experts along your side…</h3>
        </div>
      </div>
    </section>
  );
};

export default WhyWorkWithUs;
