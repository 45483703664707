import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './style.css';

gsap.registerPlugin(ScrollTrigger);

const Plans = () => {
  const sectionRef = useRef(null); // Create a reference for the section

  useEffect(() => {
    const serviceBoxes = sectionRef.current.querySelectorAll('.plan-card'); // Select all plan cards

    serviceBoxes.forEach((box, index) => {
      const directionX = index === 0 ? -200 : index === 2 ? 200 : 0; // Left for first, Right for third, 0 for middle
      const directionY = index === 1 ? 100 : 0; // Down for middle box

      gsap.fromTo(box, 
        { 
          x: directionX,
          y: directionY,
          opacity: 0 
        }, 
        { 
          x: 0,
          y: 0,
          opacity: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: box,
            start: 'top 90%', // Trigger when the top of the box hits 75% of the viewport
            end: 'bottom center', // End when the bottom of the box hits 25% of the viewport
            scrub: 1,
            onLeave: () => {
              gsap.to(box, { 
                opacity: 0, 
                duration: 0.5, 
              }); 
            },
            onEnterBack: () => {
              gsap.to(box, { 
                opacity: 1, 
                duration: 0.5, 
              }); 
            },
           
          },
        }
      );
    });
  }, []);

  return (
    <section className="plans-section text-white" ref={sectionRef} id="packages">
      <div className="container text-center">
        <h2 className="mb-5">PLANS & PRICINGS</h2>
        <p className="note">All contracts are on a monthly basis with a minimum of 3-months commitment*</p>
        <div className="row justify-content-center">
          {/* Setter Plan */}
          <div className="col-lg-4 col-md-4 mb-4 first-price">
            <div className="card plan-card plan-card-1  ">
              <div className="card-body">
                <h3 className="card-title">Setter</h3>
                <p className="sub-title">You provide the leads, we book the meetings</p>
                <ul>
                  <li>Appointment Setting Through Cold Calling</li>
                  <li>Campaign Setup With Script Writing</li>
                  <li>Campaign A/B Testing</li>
                  <li>Guaranteed 5 Qualified Appointments Per Month</li>
                </ul>
                <button 
                  className="btn btn-primary" 
                  onClick={() => window.open("https://calendly.com/paolo-upwork2000/30min", "_blank")}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>

          {/* Setter Plus Plan */}
          <div className="col-lg-4 col-md-4 mb-4">
            <div className="card plan-card plan-card-2 ">
              <div className="card-body">
                <h3 className="card-title">Setter Plus</h3>
                <p className="sub-title">We identify and qualify leads for you, then book the meetings.</p>
                <ul>
                  <li>The Whole Setter Package</li>
                  <li>Lead list building</li>
                  <li>In-Depth Market Research To Identify Ideal Prospects</li>
                </ul>
                <button 
                  className="btn btn-primary" 
                  onClick={() => window.open("https://calendly.com/paolo-upwork2000/30min", "_blank")}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>

          {/* The Closure Plan */}
          <div className="col-lg-4 col-md-4 mb-4 last-card">
            <div className="card plan-card plan-card-3  ">
              <div className="card-body">
                <h3 className="card-title">The Closer</h3>
                <p className="sub-title">Complete Sales Management and Delivery Package.</p>
                <ul>
                  <li>Setter And Setter Plus Package</li>
                  <li>Discovery Calls Include: Demo Calls, Negotiations Calls</li>
                  <li>Dedicated Sales Manager</li>
                  <li>Detailed Monthly Performance Report</li>
                </ul>
                <button 
                  className="btn btn-primary" 
                  onClick={() => window.open("https://calendly.com/paolo-upwork2000/30min", "_blank")}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>

         
        </div>
      </div>
    </section>
  );
};

export default Plans;
