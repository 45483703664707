import React from 'react'
import afterimg from '../../Assets/images/after-mob.svg'
import './style.css'
function afterMob() {
  return (


    <div className='after-mob-img'>
        <h3>CASE STUDY: PAGINATION</h3>
        <p>A pagination is a Cloud-based software that automates the creation of catalogues and price lists for enterprise manufacturers in the EMEA and US markets. They were struggling for some reason.

</p>
        <h3>AFTER</h3>
        <img src={afterimg} alt="" /></div>
  )
}

export default afterMob