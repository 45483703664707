import React, { useEffect } from "react";
import Lenis from '@studio-freight/lenis';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import "./App.css";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero-section/";
import WhyWorkWithUs from "./Components/Why-work-with-us";
import ServicesSection from "./Components/Service-section";
import CasestudySection from "./Components/Casestudy";
import VideoSection from "./Components/Founders";
import TestimonialSlider from "./Components/Testimonials";
import WhatSetsUsApart from "./Components/Whatsetsapart";
import Plans from "./Components/Pricing";
import Commitment from "./Components/CTA";
import BeforeMob from "./Components/Before-mobile";
import AfterMob from "./Components/After-mobile";

gsap.registerPlugin(ScrollTrigger);

function App() {
  useEffect(() => {
    // Initialize Lenis for smooth scrolling
    const lenis = new Lenis({
      duration: 1.2, // Adjust this value for smoother scrolling
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    // Update ScrollTrigger on Lenis scroll
    lenis.on("scroll", ScrollTrigger.update);

    // GSAP ticker to synchronize with Lenis
    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    // Optional: Lag smoothing for GSAP
    gsap.ticker.lagSmoothing(0);

    // Clean up on component unmount
    return () => {
      gsap.ticker.remove((time) => {
        lenis.raf(time * 1000);
      });
      lenis.destroy();
    };
  }, []);

  return (
    <div className="App">
      <div className="hero-wrapper">
        <Header id="section1" />
        <Hero id="section2" className="panel" />
        <WhyWorkWithUs id="section3" className="panel" />
        <ServicesSection id="section4" className="panel" />
        
        <CasestudySection
          id="section5"
          title="CASE STUDY: PAGINATION"
          description="A pagination is a Cloud-based software that automates the creation of catalogues and price lists for enterprise manufacturers in the EMEA and US markets. They were struggling for some reason."
          maintitle="BEFORE"
          keyPoints={[
            "Over-reliance on inbound leads caused inconsistent pipeline and forecasting.",
            "Struggled to get and convert leads even in the first five months due to a lack of strategy and solid system.",
            "Lacked a strong team and outbound strategy due to high costs, preventing work on a plan."
          ]}
        />

        <CasestudySection
          id="section6"
          title="CASE STUDY: PAGINATION"
          description="A pagination is a Cloud-based software that automates the creation of catalogues and price lists for enterprise manufacturers in the EMEA and US markets. They were struggling for some reason."
          maintitle="AFTER"
          keyPoints={[
            "Secured 15 enterprise client appointments, including three $2B corporations, within the first month of onboarding.",
            "Filled the pipeline with qualified leads within two months of working with us.",
            "A well-defined outbound strategy and a full pipeline have fueled their expansion into new markets."
          ]}
        />
<BeforeMob/>
<AfterMob/>

        <VideoSection id="section9" />
        <TestimonialSlider />
        <WhatSetsUsApart />
        <Plans />
        <Commitment />
      </div>
    </div>
  );
}

export default App;
