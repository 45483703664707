import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import "./style.css";

// Import required modules from Swiper
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import the company logos or icons here
import webfleet from '../../Assets/images/webfleet.svg';
import pagination from '../../Assets/images/pagination.svg';
import larin from '../../Assets/images/Larin.svg';
import lvlab from '../../Assets/images/LVlab.svg';
import repairplugin from '../../Assets/images/RepairPlugin.svg';
import impsbl from '../../Assets/images/Impssbl.svg';
import onedial from '../../Assets/images/onedial.svg';

const PartnerSection = () => {
  const companies = [
    { img: webfleet, name: 'WEBFLEET', description: 'SaaS Fleetmanagement And Tracking Solution' },
    { img: pagination, name: 'PAGINATION', description: 'SaaS Document Automation Solution' },
    { img: larin, name: 'LARIN', description: 'AI Marketing Agency' },
    { img: lvlab, name: 'LVLAB', description: 'Software Development Company With Complex Web Portals Development' },
    { img: repairplugin, name: 'PRAGMATICA', description: 'SaaS CRM For facility companies' },
    { img: impsbl, name: 'KAMPAAY', description: 'SaaS for corporate events' },
    { img: onedial, name: 'ONE DIAL SOLUTION', description: 'Contact Center Specialized In Tech' }
  ];

  return (
    <div className="second-section">
      <Container>
        <h2>TRUSTED BY 20+ STARTUPS</h2>
        <Row className="align-items-center justify-content-center">
          <Swiper
            spaceBetween={10}
            slidesPerView={7}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 4,
                spaceBetween:5,
              },
              576: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 5,
              },
              992: {
                slidesPerView: 5,
              },
              1200: {
                slidesPerView: 7,
              },
            }}
            modules={[Navigation, Pagination, Autoplay]}
            className="mySwiper"
          >
            {companies.map((company, index) => (
              <SwiperSlide key={index}>
                <Col className="company-card text-center">
                  <img src={company.img} alt={company.name} className="company-logo mb-3" />
                  <h5>{company.name}</h5>
                  <p>{company.description}</p>
                </Col>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
        <div className="partener-discription">
          <p>Your search for the ideal sales partner ends here.</p>
          <h3>Outgrow the competition with OutboundHub</h3>
          <p>We take care of your end-to-end sales so that you can focus on what you are best at.</p>
        </div>
      </Container>
    </div>
  );
};

export default PartnerSection;
