import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import newearth from '../../Assets/images/earth.png';
import newSatellite from '../../Assets/images/satelite.svg';
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const CasestudySection = ({ title, description, keyPoints, maintitle }) => {
  const beforeTextRef = useRef(null);
  const earthRef = useRef(null);
  const satelliteRef = useRef(null);
  const sectionRef = useRef(null);
  const globePointWrapperRef = useRef(null);
  const keyPointsRefs = useRef([]);

  useEffect(() => {
    const beforeText = beforeTextRef.current;
    const earth = earthRef.current;
    const satellite = satelliteRef.current;
    const keyPoints = keyPointsRefs.current;
    const globePointWrapper = globePointWrapperRef.current;

    const screenWidth = window.innerWidth;

    // Set different values for mobile, tablet, and desktop
    let earthScale = 0.8;
    let satelliteScale = 0.5;
    let satelliteX = '77%';
    let satelliteY = '300%';
    let earthX = '80%';
    let earthY = '-60%';
    let keyPointsY = '258%';
    let keyPointsX = '24%';

    // Tablet adjustments
    if (screenWidth <= 1024) {
      earthScale = 0.7;
      satelliteScale = 0.4;
      satelliteX = '50%';
      satelliteY = '200%';
      earthX = '60%';
      earthY = '-50%';
      keyPointsY = '180%';
      keyPointsX = '20%';
    }

    // Mobile adjustments
    if (screenWidth <= 769) {
      earthScale = 0.3;
      satelliteScale = 0.2;
      satelliteX = '20%';
      satelliteY = '50%';
      earthX = '56%';
      earthY = '-35%';
      keyPointsY = '150%';
      keyPointsX = '10%';
    }

    // GSAP initial setup
    gsap.set(beforeText, {
      scale: 20,
      transformOrigin: 'center center',
      zIndex: 1,
      y: 100,
    });

    gsap.set(earth, {
      scale: earthScale,
      x: earthX,
      y: earthY,
      transformOrigin: 'center center',
      width: screenWidth <= 768 ? '200px' : '300px',
      height: screenWidth <= 768 ? '200px' : '300px',
      rotation: 0,
      position: 'absolute',
      left: '50%',
      top: '30%',
      zIndex: 9,
      transform: 'translate(-50%, -50%)',
      scrub:2,
    });

    gsap.set(satellite, {
      scale: satelliteScale,
      opacity: 0,
      position: 'absolute',
      y: satelliteY,
      x: satelliteX,
      transformOrigin: 'center center',
    });

    // Initial opacity for key points
    keyPoints.forEach(point => {
      gsap.set(point, {
        opacity: 0,
        position: 'absolute',
      });
    });

    // Timeline with animation
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top", // Pin when section reaches the top
        end: "+=625", // Adjust to keep the section pinned long enough
        scrub: 2,
        pin: true, // Pin the section
      },
    })
      .to(beforeText, {
        scale: 2,
        y: 0,
        duration: 1,
        zIndex: 0,
      })
      .to(earth, {
        scale: 1,
        x: '57%',
        y: '-33%', // Bring the earth into view
        duration: 1,
      }, "<")
      .to(satellite, {
        opacity: 1,
        scale: 2,
        y: "92%",
        x: "-13%",
        duration: 1,
      }, "<0.5")
      .to(satellite, {
        x: '-260%',
        y: "98.961%",
        rotate: "84deg",
  
      })
      .to(keyPointsRefs.current[0], {
        opacity: 1,
        duration: 1,
        y: "-200%",
        x: keyPointsX,
      }, "<")
      .to(satellite, {
        x: '-225.053%',
        y: "279%",
        rotate: "136deg",
    
      })
      .to(keyPointsRefs.current[1], {
        opacity: 1,
        duration: 1,
        y: "11%",
        x: "11%",
      }, "<")
      .to(satellite, {
        x: '187%',
        y: "155%",

      })
      .to(keyPointsRefs.current[2], {
        opacity: 1,
        duration: 1,
        x: keyPointsX,
        y: keyPointsY,
      }, "<");

    // Clean up when component unmounts
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };

  }, [keyPoints]);

  return (
    <section className="before-section" ref={sectionRef} style={{ position: 'relative', textAlign: 'center', background: '#000', color: '#fff' }}>
      <h2 style={{ marginTop: '50px' }}>{title}</h2>
      <p className='subdiscription'>{description}</p>

      <div className="before-text" ref={beforeTextRef} style={{ fontWeight: 'bold', marginTop: '50px', position: 'relative' }}>
        {maintitle}
      </div>
      <div className="globe-point-wrapper" ref={globePointWrapperRef}>
        <div className="earth-wrapper" style={{ position: 'relative' }}>
          <img ref={earthRef} src={newearth} alt="Earth" className='earth2' style={{ width: '300px', height: '300px' }} />
        </div>

        <div className="satellite-wrapper">
          <img ref={satelliteRef} src={newSatellite} alt="Satellite" className='key-satelite' style={{ width: '100px', height: '100px' }} />
        </div>

        <div className="key-points">
          {keyPoints.map((point, index) => (
            <div
              key={index}
              ref={(el) => keyPointsRefs.current[index] = el}
              className={`key-point point-${index + 1}`}
            >
              <p>{point}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CasestudySection;
