import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../Assets/images/logo.png'
import "./style.css"

const Header = () => {
  return (
    <div className="header-wrapper">

  <div className="container">
      <Navbar bg="dark" expand="lg" variant="dark" className="px-3 Navbar" style={{ backgroundImage: 'url(space-background.jpg)', backgroundSize: 'cover' }}>
      <Navbar.Brand href="#home">
        <img
          src={Logo} // Your logo image
          className="d-inline-block align-top"
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="ml-auto">
          <Nav.Link href="#methodology" style={{ color: '#fff' }}>Methodology</Nav.Link>
          <Nav.Link href="#packages" style={{ color: '#fff' }}>Packages</Nav.Link>
          <Button variant="primary" href='https://calendly.com/paolo-upwork2000/30min' Style={{background:"#2B8BE4"}}className="ml-3">CONTACT US</Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
          
  </div>
  );
}

export default Header;
